<template>
  <div
    class="tags"
    v-if="mTags.length > 0"
  >
    <span
      v-for="(tag, index) in renderTagArray(mTags)"
      :key="index"
      class="tag is-white has-border"
      :class="tagCssClass"
    >{{ tag }}
    </span>
    <span
      class="tag is-white has-border"
      :class="tagCssClass"
      v-if="tagsRemaining > 0"
    >
      + {{tagsRemaining}}
    </span>
  </div>
</template>

<script>
import intlFormat from 'date-fns/intlFormat'
export default {
  name: 'Tags',

  props: {
    nrOfTags: {
      type: Number,
      default: 3
    },

    tagCssClass: {
      type: String,
      default: 'is-normal is-info'
    },

    tags: {
      type: [Array, String],
      default: function() {
        return []
      }
    }
  },

  data() {
    return {
    }
  },

  computed: {
    mTags() {
      let output = this.tags || []
      if (typeof output === 'string') {
        output = output.trim().toLowerCase().split(',')
      }
      return output
    },

    tagsRemaining() {
      return !this.nrOfTags ? 0 : this.mTags.slice(this.nrOfTags).length
    }
  },

  methods: {
    renderTagArray(arr) {
      return !this.nrOfTags ? arr : arr.slice(0, this.nrOfTags)
    },
  },
}
</script>
